body.full-width .container {
  width: 98%;
}

.shortcut-widget-box .widget-head {
  padding-top: 10px;
  padding-bottom: 10px;
}

.onboarding-widget-box {
  padding-top: 15px;
}

[data-theme=light] [data-page-route=Workspaces] .layout-main-section {
  background-color: #f9f9f9;
  box-shadow: none;
}
[data-theme=light] [data-page-route=Workspaces] .widget.spacer {
  background-color: #f9f9f9;
}

[data-theme=dark] [data-page-route=Workspaces] .layout-main-section {
  background-color: #1a1a1a;
  box-shadow: none;
}
[data-theme=dark] [data-page-route=Workspaces] .widget.spacer {
  background-color: #1a1a1a;
}

.report-button {
  margin-right: 5px;
  border: none;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 10px;
}

.app-logo {
  width: 24px !important;
}